.hover-container {
    position: relative;
}

.hover-image {
    display: block;
    opacity: 1;
  
}

.hover-text {
    display: none;
    opacity: 0;
  
}

.hover-container:hover .hover-image {
    display: none;
    transition: opacity 0.5s ease-out;
    width: 0 !important;
}

.hover-container:hover .hover-text {
    display: block;
    opacity: 1;
    transition: opacity 0.5s ease-in !important; 
}

option{
    background-color: #F5F5F5;
}
.placeholder-medium::placeholder {
    font-weight: 500; /* Adjust this to your preferred weight */
}