
:root {
	--cards: 3;
	--cardHeight: 87vh;
	--cardTopPadding: 1.5em;
	--cardMargin: 4vw;
}

.container {
	width: 90%;
	margin: 0 auto;
}

#cards {
	list-style: none;
	padding-left: 0;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(var(--cards), var(--cardHeight));
	gap: var(--cardMargin);
	padding-bottom: calc(var(--cards) * var(--cardTopPadding));
	margin-bottom: var(--cardMargin);
}

#card1 {
	--index: 1;
}
#card2 {
	--index: 2;
}
#card3 {
	--index: 3;
}


.card {
	position: sticky;
	top: 0;
	padding-top: calc(var(--index) * var(--cardTopPadding));
}

.card-body {
	box-sizing: border-box;
	padding: 30px;
	border-radius: 50px;
	box-shadow: 0 0 30px 0 rgba(0,0,0,0.3);
	height: var(--cardHeight);
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s;
}
