@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

body {
  margin: 0;
  font-family: "Lato", sans-serif;
}
